/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RangeSlider from '../../../elements/calculatorRange/rangeSlider';
import withinRange from '../../../utilities/numberHelpers';
import { formatCurrency } from '../../../utilities/formatters';
import { getSmallLoanRepayment, getPersonalLoanRepayment } from '../../../utilities/calculatorLogic';

const CalculatorForm = styled.form`
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 10px;
  box-sizing: border-box;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding: 40px 40px 24px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0;
    box-shadow: none;
  }  
`;

const Field = styled.div`
  margin-bottom: 30px;
  width: 100%;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin-bottom: 25px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) and (max-height: 820px) {
    margin-bottom: 20px;
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
`;

const RepaymentDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DetailsContainer = styled.div`
  display: flex;
  font-weight: 600;
`;

const DetailsItem = styled.div`
  margin-right: 25px;
  color: ${props => props.theme.colours.nimbleRed};

  &:last-child {
    margin-right: 0;
  }

  @media(max-width: 1300px){
    text-align: center;
  }
`;

const ReplaymentAmountText = styled.span`
  display: inline-block;
  margin-right: 2px;
  font-size: 30px;
  line-height: 24px;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 24px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 21px;
    margin-right: 1px;
  }
  
  @media(max-width: 320px) {
    font-size: 18px;
  } 
`;

const RepaymentFrequencyText = styled.span`
  display: inline-block;
  font-size: 14px;

  @media(max-width: 1300px) {
    font-size: 12px;
  }

  @media(max-width: 300px) {
    display: block;
  }
`;

const RepaymentDisclaimer = styled.p`
  color: ${props => props.theme.colours.neutralText};
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
  min-height: 56px;

  @media(max-width: 1300px) {
    min-height: 84px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    min-height: 56px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    order: 10;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    min-height: unset;
    text-align: left;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 24px auto 0;
`;

const StyledButton = styled.button`
  width: 100%;
  border-radius: 4px;
  background-color: ${props => props.theme.colours.green};
  border: none;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  transition: 0.35s ease;
  text-transform: none;
  cursor: pointer;
  font-family: ${props => props.theme.font.nimbleFont};
  font-weight: 600;
  padding: 12px 30px;
  box-sizing: border-box;

  @media(hover:hover) {
    &:hover {
      background-color: ${props => props.theme.colours.nimbleRed};
      color: #ffffff;    
    }
  }
`;

const Calculator = ({ calculatorData }) => {
  const {
    creditTitle,
    defaultCreditAmount,
    bracketsData,
    applyLink
  } = calculatorData;

  const getBracketForAmount = (amount) => {
    return bracketsData.find(d => amount >= d.min && amount <= d.max);
  };

  const creditMin = Math.min(...bracketsData.map(o => o.min));
  const creditMax = Math.max(...bracketsData.map(o => o.max));

  const smallestStep = Math.min(...bracketsData.map(o => o.step));

  let initCreditLimit;

  if (defaultCreditAmount > creditMax) {
    initCreditLimit = creditMax;
  } else if (defaultCreditAmount < creditMin) {
    initCreditLimit = creditMin;
  } else {
    initCreditLimit = defaultCreditAmount;
  }

  const initBracket = getBracketForAmount(initCreditLimit);

  const [establishmentFee, setEstablishmentFee] = useState(initBracket.establishmentFee);
  const [repaymentMin, setRepaymentMin] = useState(initBracket.minLoanLength);
  const [repaymentMax, setRepaymentMax] = useState(initBracket.maxLoanLength);
  const [creditStep, setCreditStep] = useState(initBracket.step);
  const [termInterval, setTermInterval] = useState(initBracket.termInterval);
  const [repaymentPeriod, setRepaymentPeriod] = useState(initBracket.defaultLoanLength);
  const [disclaimer, setDisclaimer] = useState(initBracket.disclaimer);
  const [creditLimit, setCreditLimit] = useState(initCreditLimit);
  const [oldCreditLimit, setOldCreditLimit] = useState(initCreditLimit);
  const [repaymentAmount, setRepaymentAmount] = useState(0);
  const [creditAdjusted, setCreditAdjusted] = useState(false);

  const getAmountRoundedUpToNearestStep = (amount, step) => {
    const diff = amount % step;

    if (diff !== 0) {
      if (oldCreditLimit > amount && oldCreditLimit - amount < step) {
        return amount - diff; // this will round DOWN to the nearest multiple of step
      }
      return amount + (step - diff); // this will round UP to the nearest multiple of step
    }

    return amount;
  };

  const updateRepayment = async () => {
    // we do this here to make sure we have latest rates and fees
    const bracket = getBracketForAmount(creditLimit);

    if (bracket) {
      const newRepayment = bracket.loanType === 'small'
        ? await getSmallLoanRepayment(
          creditLimit,
          repaymentPeriod
        ) : await getPersonalLoanRepayment(
          creditLimit,
          repaymentPeriod,
          bracket.interestRate,
          bracket.establishmentFee
        );

      setRepaymentAmount(newRepayment);
    }
  };

  useEffect(() => {
    updateRepayment();
  }, [creditLimit, repaymentPeriod]);

  useEffect(() => {
    const bracket = getBracketForAmount(creditLimit);

    if (bracket) {
      if (creditStep !== bracket.step) {
        setCreditStep(bracket.step);
      }

      if (repaymentMin !== bracket.minLoanLength) {
        setRepaymentMin(bracket.minLoanLength);

        if (repaymentPeriod < bracket.minLoanLength) {
          setRepaymentPeriod(bracket.minLoanLength);
        }
      }

      if (repaymentMax !== bracket.maxLoanLength) {
        setRepaymentMax(bracket.maxLoanLength);

        if (repaymentPeriod > bracket.maxLoanLength) {
          setRepaymentPeriod(bracket.maxLoanLength);
        }
      }

      if (termInterval !== bracket.termInterval) {
        setTermInterval(bracket.termInterval);
      }

      if (establishmentFee !== bracket.establishmentFee) {
        setEstablishmentFee(bracket.establishmentFee);
      }

      if (disclaimer !== bracket.disclaimer) {
        setDisclaimer(bracket.disclaimer);
      }

      if (creditAdjusted === false) {
        const amountRoundedUpToNearestStep = getAmountRoundedUpToNearestStep(
          creditLimit,
          bracket.step
        );

        if (creditLimit !== amountRoundedUpToNearestStep) {
          const adjustment = withinRange(
            Number.parseInt(amountRoundedUpToNearestStep, 10),
            creditMin,
            creditMax
          );
          setOldCreditLimit(adjustment);
          setCreditLimit(adjustment);
          setCreditAdjusted(true);
        } else {
          setOldCreditLimit(creditLimit);
        }
      } else {
        setCreditAdjusted(false);
      }
    }
  }, [creditLimit]);

  return (
    <CalculatorForm action={applyLink && applyLink.linkUrl} method="get">
      <Field>
        <StyledLabel>{creditTitle}</StyledLabel>
        <RangeSlider
          onChangeCallback={setCreditLimit}
          inputName="loanAmount"
          ariaLabel="Loan amount"
          value={creditLimit}
          sliderStep={smallestStep}
          hasButtons
          buttonStep={500}
          min={creditMin}
          max={creditMax}
          formatter={formatCurrency}
          hasToolitip
        />
      </Field>
      <Field>
        <StyledLabel>Loan term</StyledLabel>
        <RangeSlider
          onChangeCallback={setRepaymentPeriod}
          inputName="loanTerm"
          ariaLabel="Loan term"
          value={repaymentPeriod}
          sliderStep={1}
          hasButtons
          buttonStep={1}
          min={repaymentMin}
          max={repaymentMax}
          formatter={e => `${e} ${termInterval}`}
          hasToolitip
        />
      </Field>
      <RepaymentDetailsContainer>
        <RepaymentDisclaimer>{disclaimer}</RepaymentDisclaimer>
        <DetailsContainer>
          <DetailsItem>
            <ReplaymentAmountText>
              {repaymentAmount.week && (`$${repaymentAmount.week.toFixed(0)}`)}
            </ReplaymentAmountText>
            <RepaymentFrequencyText>/week</RepaymentFrequencyText>
          </DetailsItem>
          <DetailsItem>
            <ReplaymentAmountText>
              {repaymentAmount.fortnight && (`$${repaymentAmount.fortnight.toFixed(0)}`)}
            </ReplaymentAmountText>
            <RepaymentFrequencyText>/fortnight</RepaymentFrequencyText>
          </DetailsItem>
        </DetailsContainer>
        {applyLink && (
          <ButtonContainer>
            <StyledButton type="submit">{applyLink.linkText}</StyledButton>
          </ButtonContainer>
        )}
      </RepaymentDetailsContainer>
    </CalculatorForm>
  );
};

Calculator.propTypes = {
  calculatorData: PropTypes.shape({
    creditTitle: PropTypes.string,
    defaultCreditAmount: PropTypes.number,
    bracketsData: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      )
    ),
    repaymentAmountTitle: PropTypes.string,
    applyLink: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string
    })
  })
};
export default Calculator;
