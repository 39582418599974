import React from 'react';
import styled, {css}  from 'styled-components';
import PropTypes from 'prop-types';
import { COLOURS } from './buttonColors';

const ButtonContainer = styled.div`
  width: 100%;
`;
const StyledButton = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: ${props => COLOURS[props.color]};
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  cursor: pointer;
  font-family: ${props => props.theme.font.nimbleFont};
  ${props => props.color === 'GREEN' && css`
  transition: 0.35s ease;
    @media (hover:hover) {
      &:hover {
        background-color: ${props.theme.colours.slate};
        color: #ffffff;
      }
    }
`}
`;
const StyledLink = styled.a``;
const LinkButton = ({
  text, color, width, mobileWidth, link, onclick
}) => {
  return (
    <ButtonContainer>
      <StyledLink href={link}>
        <StyledButton width={width} color={color} mobileWidth={mobileWidth} onClick={onclick}>{text}</StyledButton>
      </StyledLink>
    </ButtonContainer>
  );
};

export default LinkButton;
