import styled from 'styled-components';
import { theme } from '../../theme/theme-vars';
import starMask from '../../../static/images/star_5_mask.svg';

const StarsElementStyles = styled.span`
  display: flex;
  .stars {
    position: relative;
    display: inline-block;
    font-family: arial;
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 1;

    .stars-empty {
      opacity: 0;
    }

    .stars-filled {
      position: absolute;
      left: 0;
      top: 0;
      color: #FFD600;
    }
  }

  .google-stars {
    width: 102px;
    height: 17px;
    position: relative;
    background-color: ${theme.misc.starEmptyColor};

    .google-stars-fill {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: ${theme.misc.starFillColor};
    }

    .google-stars-mask {
      display: block;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background-image: url(${starMask});
      background-size: 100% 100%;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: solid 1px #fff;
      outline: solid 1px #fff;
    }
  }
`;

export default StarsElementStyles;


