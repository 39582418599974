export const formatPercentage = (rate, isPercentage) => {
  let safeRate = rate;
  if (safeRate === null || safeRate === undefined) {
    safeRate = 0;
  }
  if (typeof safeRate === 'string') {
    safeRate = parseFloat(safeRate);
  }
  return new Intl.NumberFormat(
    'en-AU',
    { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }
  ).format(isPercentage ? safeRate / 100 : safeRate);
};
export const formatCurrency = (money, decimalDigits = 0) => {
  let input = money;
  if (money === undefined || money === null || money === false) {
    input = 0;
  }
  return new Intl.NumberFormat(
    'en-AU',
    {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: decimalDigits,
      maximumFractionDigits: decimalDigits
    }
  ).format(input);
};

export const formatDateTime = (date, options) => {
  let defaultOptions = options;

  if (date === null || date === undefined || date === '') {
    return '';
  }

  const safeDate = new Date(date);

  if (defaultOptions === null || defaultOptions === undefined) {
    defaultOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
  }
  return new Intl.DateTimeFormat('en-AU', defaultOptions).format(safeDate);
};

const MOBILE_REGEX = /(04\d{4})(\d{1})(\d{3})/gi;
export const formatMobileWithMask = (phoneNumber) => {
  if (phoneNumber === undefined || phoneNumber == null) {
    return '';
  }
  return phoneNumber.replace(MOBILE_REGEX, '+61 XXX XX$2 $3');
};
const MOBILE_FORMAT_REGEX = /04(\d{2})(\d{3})(\d{3})/gi;
export const formatMobile = (phoneNumber) => {
  if (phoneNumber === undefined || phoneNumber == null) {
    return '';
  }
  return phoneNumber.replace(MOBILE_FORMAT_REGEX, '+61 4$1 $2 $3');
};

export const formatMaxCharacterLimit = (
  base,
  maxCharacterIndex,
  replacement
) => (
  base.length > maxCharacterIndex ? base.substring(0, maxCharacterIndex) + replacement : base
);

export const formatSentenceCase = (text) => {
  const firstChar = text.charAt(0).toUpperCase();
  const remainingChar = text.slice(1).toLowerCase();
  return firstChar + remainingChar;
};
