import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 24px;
  background: ${props => props.theme.colours.lightGreen};
`;
const StyledHeading = styled.h2`
  font-style: normal;
  font-weight: 600;
  text-align: center;
  max-width: 840px;
  margin-bottom: 30px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    margin: 0 16px 24px;
  }
`;

const FindLoanBtn = styled(Button)`
  background: ${props => props.theme.colours.darkGreen};
  border-radius: 4px;
  font-family: ${props => props.theme.font.nimbleFont};
  font-weight: 600;
  font-size: 16px;
  padding: 12px 0;
  text-align: center;
  color: #ffffff;
  width: 310px;
  border: none;
  text-transform: none;
  cursor: pointer;
  transition: 0.35s ease;

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    width: 100%;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: #ffffff;
      background-color: ${props => props.theme.colours.slate};
    }
  }
`;

const EndOfPage = ({ applyLink, applyName, onClickApplyNow }) => (
  <StyledContainer>
    <StyledHeading>
      Whoa, you&apos;ve got some impressive scrolling skills. But, this is near
      the end of the page. Click below to
      {applyName ? ` apply for ${applyName}.` : ' find a Nimble Loan for you.'}
    </StyledHeading>
    {onClickApplyNow && (
      <FindLoanBtn onClick={onClickApplyNow}>Apply now</FindLoanBtn>
    )}
    {applyLink && (
      <FindLoanBtn as="a" href={applyLink}>
        Apply now
      </FindLoanBtn>
    )}
  </StyledContainer>
);

EndOfPage.propTypes = {
  applyLink: PropTypes.string,
  applyName: PropTypes.string
};

export default EndOfPage;
