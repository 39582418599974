import React, { useState, useEffect } from 'react';
// Styles
import StarsElementStyles from './styles';

const Stars = (props) => {
  const [clipPolygonStyle, setClipPolygonStyle] = useState(
    'polygon(0 0, 100% 0, 100% 100%, 0 100%; -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%;)',
  );
  useEffect(() => {
    const width = Math.min((props.stars * 100) / 5);
    setClipPolygonStyle(`polygon(0 0, ${width}% 0, ${width}% 100%, 0 100%)`);
  }, [props.stars]);

  const starsClipStyle = {
    clipPath: clipPolygonStyle,
    WebkitClipPath: clipPolygonStyle,
  };

  return (
    <StarsElementStyles>
      <div className="stars">
        {props.hollow ? (
          <div className="stars-empty">☆☆☆☆☆</div>
        ) : (
          <div>
            <div className="stars-empty">★★★★★</div>
            <div className="stars-filled" style={starsClipStyle}>
              ★★★★★
            </div>
          </div>
        )}
      </div>
    </StarsElementStyles>
  );
};

export default Stars;
