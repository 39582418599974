import React from 'react';
import loadable from '@loadable/component';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ProductReviewLogo from '../../../static/images/productreview-logo.svg';
import GoogleReviewLogo from '../../../static/images/google-g-logo.svg';

// Elements
import Stars from '../../elements/stars/index';

const Carousel = loadable(() => import('../../elements/slickSlider'));

const StyledSection = styled.div`
  padding: 64px 0px;
  ${props => props.background === 'White' && css`
    background: #ffffff;
  `}

  ${props => props.background === 'Red' && css`
    background: ${props.theme.colours.nimbleRed};
  `}

  ${props => props.background === 'Pink' && css`
    background: ${props.theme.colours.pink};
  `}

  ${props => props.background === 'Grey' && css`
    background: ${props.theme.colours.greyBack};
  `}

  ${props => props.background === 'Cream' && css`
    background: ${props.theme.colours.cream};
  `}

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 40px 0px;
  }
`;

const StyledContainer = styled.div`
  padding: 0px 60px;
  max-width: 1403px;
  margin: auto;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 0px 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 0px 24px;
  }
`;

const TestimonialsHead = styled.div` 
  @media(min-width: ${props => props.theme.breakpoints.min.xs}) {
    padding-right: 90px;
  }
`;

const StyledHeading = styled.h2`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.font.nimbleFont};
  font-style: normal;
  font-size: 29px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 7px;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 20px;
    line-height: 24px; 
  }  
`;

const StyledSubheading = styled.p`
  color: ${props => props.theme.colours.black};
  margin: 0;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 14px;
    line-height: 20px; 
  } 
`;

const TestimonialsCarousel = styled.div` 
  margin-top: 24px;
  margin-left: -12px;
  margin-right: -12px;

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    transition: opacity 0.25s ease;

    & > div {
      height: 100%;
    }
  }

  .slick-next, .slick-prev {
    top: -44px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${props => props.theme.colours.darkGreen};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 47%;
      width: 7px;
      height: 7px;
      transition: 0.35s ease;
    }

    &:focus, &:hover{
      background-color: ${props => props.theme.colours.darkGreen};
    }
  }

  .slick-prev {
    left: auto;
    right: 55px;

    &::before {
      left: 15px;
      border-left: 2px solid #ffffff;
      border-top: 2px solid #ffffff;
      transform: rotate(-45deg) translateY(-50%);
    }

    &:focus, &:hover{
      &::before {
       left: 13px;
      }
    }
  }

  .slick-next {
    right: 10px;
    &::before {
      left: 6px;
      border-right: 2px solid #ffffff;
      border-top: 2px solid #ffffff;
      transform: rotate(45deg) translateY(-50%);
    }

    &:focus, &:hover{
      &::before {
       left: 8px;
      }
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin-left: -24px;
    margin-right: -24px;

    .slick-list {
      padding-left: 10px !important;
    }
  }  

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding-top: 34px;

    .slick-next, .slick-prev {
      top: -26px;
    }

    .slick-prev {
      left: 20px;
      right: auto;
    }

    .slick-next {
      left: 64px;
      right: auto;
    }
  }  
`;

const TestimonialContainer = styled.div`
  padding: 4px 12px;
  height: 100%;

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 4px 10px;
  }  
`;

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 15px;
  padding: 16px;
  height: 100%;
`;

const TestimonialQuote = styled.blockquote` 
  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 14px;
    line-height: 20px; 
  } 
`;

const TestimonialFooter = styled.div` 
  margin-top: auto;
  padding-top: 16px;
  display: flex;
  align-items: center;
`;

const TestimonialPhoto = styled.img` 
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 16px;
`;

const TestimonialText = styled.div` 
  flex: 1;
  font-size: 20px;
  line-height: 24px; 

  .stars {
    text-align: left;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    font-size: 18px;
    line-height: 20px; 
  } 

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 16px;
  } 
`;

const TestimonialCompanyLogo = styled.img`
  width: 14px;
  align-self: flex-end;
`;

export default function TestimonialsComponent({ data }) {
  const {
    testimonialsHeading: heading,
    testimonialsSubheading: subheading,
    hashId,
    testimonials,
    componentBackground
  } = data;

  const carouselSettings = {
    autoplaySpeed: 3250,
    autoplay: true,
    infinite: testimonials.length > 4,
    slidesToShow: 4,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          infinite: testimonials.length > 3,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          infinite: testimonials.length > 2,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 550,
        settings: {
          infinite: testimonials.length > 1,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '18%'
        }
      },
      {
        breakpoint: 400,
        settings: {
          infinite: testimonials.length > 1,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '7%'
        }
      }
    ]
  };

  return (
    <StyledSection id={hashId} background={componentBackground}>
      <StyledContainer>
        <TestimonialsHead>
          {heading && <StyledHeading>{heading}</StyledHeading>}
          {subheading && <StyledSubheading>{subheading}</StyledSubheading>}
        </TestimonialsHead>
        <TestimonialsCarousel>
          <Carousel settings={carouselSettings}>
            {testimonials.map((testimonial) => (
              <TestimonialContainer key={testimonial.name}>
                <Testimonial>
                  <TestimonialQuote>{testimonial.quote.quote}</TestimonialQuote>
                  <TestimonialFooter>
                    {testimonial.photo && <TestimonialPhoto src={testimonial.photo.gatsbyImage.images.fallback.src} />}
                    
                    <TestimonialText>
                      <span>
                        {testimonial.name}
                      </span>
                      {testimonial.date && (
                        <span>
                          {', '}
                          {testimonial.date}
                        </span>
                      )}
                      {testimonial.stars && <Stars stars={testimonial.stars} />}
                    </TestimonialText>

                    {testimonial.reviewsPlatform && (
                      <TestimonialCompanyLogo
                        src={testimonial.reviewsPlatform === 'ProductReview' ? ProductReviewLogo : GoogleReviewLogo}
                        alt={`${testimonial.reviewsPlatform === 'ProductReview' ? 'Product Review' : 'Google Review'} icon`}
                      />
                    )}
                  </TestimonialFooter>
                </Testimonial>
              </TestimonialContainer>
            ))}
          </Carousel>
        </TestimonialsCarousel>
      </StyledContainer>
    </StyledSection>
  );
}

TestimonialsComponent.propTypes = {
  data: PropTypes.shape({
    hashId: PropTypes.string,
    componentBackground: PropTypes.string,
    testimonialsHeading: PropTypes.string,
    testimonialsSubheading: PropTypes.string,
    testimonials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      stars: PropTypes.number,
      date: PropTypes.string,
      quote: PropTypes.shape({
        quote: PropTypes.string
      }),
      reviewsPlatform: PropTypes.string
    }))
  })
};
