import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ArrowDownIcon from '../../../static/images/triangle-down-gray.svg';

const StyledContainer = styled.div`
  display: flex;
  position: relative;
`;
const StyledSelect = styled.select`
  border: 2px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
  font-family: ${props => props.theme.font.nimbleFont};
  padding: 14px 0 12px 12px;
  background-color: white;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    border-color: #80bdff;
    border-radius: 4px;
    outline: none;
  }
`;
const ArrowIcon = styled.img`
    position: absolute;
    top: 46%;
    right: 15px;
    pointer-events: none;
`;

const Select = ({ children, name, value, onChange }) => {
  return (
    <StyledContainer>
      <StyledSelect name={name} value={value} onChange={onChange}>{children}</StyledSelect>
      <ArrowIcon src={ArrowDownIcon} />
    </StyledContainer>
  );
};

Select.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func
};
export default Select;
