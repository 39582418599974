import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import FAQSection from './faqSection';

const StyledSection = styled.div`
  padding: 64px 0px;
  ${props => props.background === 'White' && css`
    background: #ffffff;
  `}

  ${props => props.background === 'Red' && css`
    background: ${props.theme.colours.nimbleRed};
  `}

  ${props => props.background === 'Pink' && css`
    background: ${props.theme.colours.pink};
  `}

  ${props => props.background === 'Grey' && css`
    background: ${props.theme.colours.greyBack};
  `}

  ${props => props.background === 'Cream' && css`
    background: ${props.theme.colours.cream};
  `}

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 40px 0px;
  }
`;

const StyledContainer = styled.div`
  padding: 0px 60px;
  max-width: 1403px;
  margin: auto;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 0px 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 0px 24px;
  }
`;

const HeadSection = styled.div`
  display: flex;
  align-items: center;

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    display: block;
    margin-bottom: 15px;
  }  
`;

const Heading = styled.h2`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.font.nimbleFont};
  font-style: normal;
  font-size: 29px;
  line-height: 32px;
  font-weight: 600;
  padding-right: 20px;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 7px;
  }  
`;

const StyledLink = styled.a`
  display: block;
  position: relative;
  margin-left: auto;
  margin-bottom: 20px;
  padding-right: 15px;
  color: ${props => props.theme.colours.darkGreen};
  transition: 0.35s ease;
  flex: 0 0 auto;

  &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 40%;
      right: 0px; 
      margin-right: 5px;
      border-right: 2px solid ${props => props.theme.colours.darkGreen};
      border-top: 2px solid ${props => props.theme.colours.darkGreen};
      width: 7px;
      height: 7px;
      transform: rotate(45deg) translateY(-50%);
      transition: 0.35s ease;
    }

    @media (hover:hover) {
      &:hover{
        color: ${props => props.theme.colours.black};

        &::after {
          margin-right: 2px;
          border-right-color: ${props => props.theme.colours.black};
          border-top-color: ${props => props.theme.colours.blakc};
        }
      }
    }

    @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
      display: inline;
    }  
`;

const SectionContainer = styled.div`
  ${props => props.flex && css`
    @media (min-width: ${props.theme.breakpoints.min.lg}) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;
    }
  `}
`;

const Section = styled.div`
  ${props => props.flex && css`
    @media (min-width: ${props.theme.breakpoints.min.lg}) {
      padding: 0 20px;
      flex: 0 0 50%;
    }

    @media (max-width: ${props.theme.breakpoints.max.lg}) {
      margin-bottom: 40px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  `}
`;

const FAQComponent = ({ data }) => {
  const {
    hashId,
    componentBackground,
    faqHeading,
    viewAllLink,
    faqSections
  } = data;

  return (
    <>
      <StyledSection background={componentBackground} id={hashId}>
        <StyledContainer>
          <HeadSection>
            {faqHeading && (<Heading>{faqHeading}</Heading>)}
            {viewAllLink && (
              <StyledLink href={viewAllLink.linkUrl} target={viewAllLink.openNewTab ? '_blank' : '_self'}>{viewAllLink.linkText}</StyledLink>
            )}
          </HeadSection>

          <SectionContainer flex={faqSections.length !== 1}>
            {faqSections && (
              <>
                {faqSections.map((section) => (
                  <Section flex={faqSections.length !== 1} key={section.faqQuestions}>
                    <FAQSection
                      heading={section.faqsHeading}
                      questions={section.faqQuestions}
                      length={faqSections.length}
                    />
                  </Section>
                ))}
              </>
            )}
          </SectionContainer>
        </StyledContainer>
      </StyledSection>
    </>
  );
};

FAQComponent.propTypes = {
  data: PropTypes.shape({
    hashId: PropTypes.string,
    faqHeading: PropTypes.string,
    viewAllLink: PropTypes.shape({
      linkText: PropTypes.string,
      linkUrl: PropTypes.string,
      openNewTab: PropTypes.bool
    }),
    componentBackground: PropTypes.string,
    faqSections: PropTypes.arrayOf(PropTypes.shape({
      faqsHeading: PropTypes.string,
      faqQuestions: PropTypes.arrayOf(PropTypes.shape({
        question: PropTypes.objectOf(PropTypes.string),
        answer: PropTypes.objectOf(PropTypes.string)
      }))
    }))
  })
};

export default FAQComponent;
