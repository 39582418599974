/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { PMT } from '@formulajs/formulajs/lib/financial';

const percentageRepayment = (interestRate, numberOfRepayments) =>
  interestRate + interestRate / ((1 + interestRate) ** numberOfRepayments - 1);

export const getAnytimeLoanRepayment = (
  anytimeInterestRate,
  creditLimit,
  repaymentPeriod
) => {
  const workingInterestRate = anytimeInterestRate / 100;

  const monthlyInterestRate = workingInterestRate / 12;
  const monthlyRepayment = percentageRepayment(monthlyInterestRate, repaymentPeriod) * creditLimit;

  const fortnightlyInterestRate = workingInterestRate / 26;
  const numberOfFortnightlyRepayments = Math.floor((repaymentPeriod / 12) * 26);
  const fortnightlyRepayment = percentageRepayment(fortnightlyInterestRate, numberOfFortnightlyRepayments) * creditLimit;

  const weeklyInterestRate = workingInterestRate / 52;
  const numberOfWeeklyRepayments = Math.floor((repaymentPeriod / 12) * 52);
  const weeklyRepayment = percentageRepayment(weeklyInterestRate, numberOfWeeklyRepayments) * creditLimit;

  const repayment = {
    week: weeklyRepayment,
    fortnight: fortnightlyRepayment,
    month: monthlyRepayment
  };

  return repayment;
};

export const getSmallLoanRepayment = (
  principle,
  termWeeks
) => {
  const numberOfFees = Math.ceil(((termWeeks * 7) / 365) * 12);
  const establishmentFee = principle * 0.2;
  const monthlyfee = principle * 0.04;
  const totalLoan = principle + establishmentFee + (monthlyfee * numberOfFees);
  const repayment = {
    week: totalLoan / termWeeks,
    fortnight: (totalLoan / termWeeks) * 2,
    month: ((totalLoan / termWeeks) * 52) / 12
  };

  return repayment;
};

export const getPersonalLoanRepayment = (
  creditLimit,
  repaymentPeriod,
  interestRate,
  establishmentFee = 0
) => {
  const totalLoan = creditLimit + establishmentFee;
  const workingInterestRate = interestRate / 100;

  const numberOfMonthlyRepayments = repaymentPeriod;
  const monthlyInterestRate = (workingInterestRate / 12) * numberOfMonthlyRepayments;
  const monthlyRepayment = PMT(monthlyInterestRate / numberOfMonthlyRepayments, numberOfMonthlyRepayments, -totalLoan, 0, 0);

  const numberOfFortnightlyRepayments = Math.floor((repaymentPeriod / 12) * 26);
  const fortnightlyInterestRate = (workingInterestRate / 26) * numberOfFortnightlyRepayments;
  const fortnightlyRepayment = PMT(fortnightlyInterestRate / numberOfFortnightlyRepayments, numberOfFortnightlyRepayments, -totalLoan, 0, 0);

  const numberOfWeeklyRepayments = Math.floor((repaymentPeriod / 12) * 52);
  const weeklyInterestRate = (workingInterestRate / 52) * numberOfWeeklyRepayments;
  const weeklyRepayment = PMT(weeklyInterestRate / numberOfWeeklyRepayments, numberOfWeeklyRepayments, -totalLoan, 0, 0);

  const repayment = {
    week: weeklyRepayment,
    fortnight: fortnightlyRepayment,
    month: monthlyRepayment
  };
  return repayment;
};
