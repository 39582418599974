import { css } from 'styled-components';

export const baseInput = css`
  background: ${props => props.theme.colours.contrast};
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colours.slateLight};
  padding: 12px 15px;
  width: 100%;
  margin-bottom: 5px;
  color: ${props => props.theme.colours.slate};
  font-family: ${props => props.theme.font.nimbleFont};
  font-size: 16px;
  border-color: ${props => (props.error ? props.theme.colours.primary.light : '')};
  border-width: ${props => (props.error ? '2px' : '')};
  box-sizing: border-box;
   
  :disabled {    
    color: ${props => props.theme.colours.unselected}; 
  }
  
  ::-webkit-input-placeholder,
  ::-ms-input-placeholder,
  ::placeholder {
    color: ${props => props.theme.colours.slateLight};
  }
`;
