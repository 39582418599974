import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FAQQuestion from './faqQuestion';

const FaqsContainer = styled.div`
  margin-top: 15px;
`;

const FaqHeading = styled.h3`
  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    font-size: 16px;
    line-height: 20px;
  }
`;

const SectionContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.min.lg}) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
  }
`;

const QuestionsContainer = styled.div`
  flex: 0 0 50%;

  @media (min-width: ${props => props.theme.breakpoints.min.lg}) {
    padding: 0 20px;
  }
`;

const FAQSection = ({ heading, questions, length }) => {
  let leftSectionData;
  let rightSectionData;
  if (length === 1) {
    const half = Math.ceil(questions.length / 2);
    const questiosnData = [...questions];
    leftSectionData = questiosnData.splice(0, half);
    rightSectionData = questiosnData.splice(-half);
  }

  return (
    <FaqsContainer>
      {heading && <FaqHeading>{heading}</FaqHeading>}
      {length === 1 ? (
        <SectionContainer>
          <QuestionsContainer>
            {
              leftSectionData.map(({
                question,
                answer
              }, index) => {
                return (
                  <FAQQuestion
                    key={question.question}
                    uid={`${heading}-${question.question}-${index}`}
                    question={question.question}
                    answer={answer}
                  />
                );
              })
            }
          </QuestionsContainer>
          <QuestionsContainer>
            {
              rightSectionData.map(({
                question,
                answer
              }, index) => {
                return (
                  <FAQQuestion
                    key={question.question}
                    uid={`${heading}-${question.question}-${index}`}
                    question={question.question}
                    answer={answer}
                  />
                );
              })
            }
          </QuestionsContainer>
        </SectionContainer>

      ) : (
        <>
          {
            questions.map(({
              question,
              answer
            }, index) => {
              return (
                <FAQQuestion
                  key={question.question}
                  uid={`${heading}-${question.question}-${index}`}
                  question={question.question}
                  answer={answer}
                />
              );
            })
          }
        </>
      )}

    </FaqsContainer>
  );
};

FAQSection.propTypes = {
  heading: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.shape({
      question: PropTypes.string
    }),
    answer: PropTypes.shape({
      raw: PropTypes.string
    })
  })),
  length: PropTypes.number
};

export default FAQSection;
