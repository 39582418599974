/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import RichText from '../../elements/richText';

const FaqItemContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colours.greytext};
  padding: 26px 0;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 16px 0;
  }
`;

const FaqItemToggle = styled.button`
  display: block;
  font-family: ${props => props.theme.font.nimbleFont};
  position: relative;
  width: 100%;
  text-align: left;
  color: ${props => props.theme.colours.black};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
  background-color: transparent;
  border: 0;
  padding: 0 40px 0 0;
  transition: color 0.35s ease;
  cursor: pointer;

  &::after {
    content: '+';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    font-family: Arial;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    transition: 0.35s ease;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 0 60px 0 0;
    font-size: 14px;
    line-height: 20px;

    &::after {
      top: -5px;
    }
  }

  @media(hover: hover) {
    &:hover{
      color: ${props => props.theme.colours.darkGreen};
    }
  }

  ${props => props.expanded && css`
    &::after {
      transform: rotate(225deg);
    }
  `}
`;

const FaqAnswer = styled.div`
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: 0.5s ease;

  ${props => props.expanded && css`
    max-height: 1200px;
    opacity: 1;
    transition: 0.75s ease;
  `}

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: ${props => props.theme.colours.darkGreen};
    text-decoration: underline;
    transition: 0.35s ease;

    &:hover{
      color: ${props => props.theme.colours.slate};
    }
  }

`;

const FaqAnswerText = styled.div`
  font-size: 14px;
  font-family: ${props => props.theme.font.nimbleFont};

  p{
    &:last-child{
      display: inline;
    }
    &:first-child{
      display: block;
    }
  }

  li {
    font-size: 14px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    font-size: 12px;
    line-height: 18px;

    li {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const FAQQuestion = ({
  uid,
  question,
  answer
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <FaqItemContainer>
      <FaqItemToggle
        id={`FaqsItemToggle-${uid}`}
        aria-controls={`FaqsItem-${uid}`}
        aria-expanded="false"
        onClick={() => setExpanded(!expanded)}
        expanded={expanded}
      >
        {question}
      </FaqItemToggle>
      <FaqAnswer
        aria-labelledby={`FaqsItemToggle-${uid}`}
        id={`FaqsItem-${uid}`}
        expanded={expanded}
      >
        <FaqAnswerText>
          <RichText text={answer} />
        </FaqAnswerText>
      </FaqAnswer>
    </FaqItemContainer>
  );
};

FAQQuestion.propTypes = {
  uid: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.shape({
    raw: PropTypes.string
  })
};

export default FAQQuestion;
