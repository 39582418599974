/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import StarFull from '../../../../static/images/star-full.svg';
import StarHalf from '../../../../static/images/star-half.svg';

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0;
  padding: 0 15px;
  min-width: 200px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    min-width: 190px;
    padding: 0 5px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PlatformImage = styled.img`
  flex: 0 0 35px;
  width: 35px;
`;

const RatingContent = styled.div`
  flex: 1;
  padding-left: 15px;
`;

const PlatformName = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
`;

const PlatformRating = styled.div`
  margin: 0px;
`;

const RatingStar = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 2px;
  background-size: contain;
  background-position: center left;
  background-repeat: no-repeat;

  ${props => props.full && css`
    background-image: url(${StarFull});
  `}

  ${props => props.half && css`
    background-image: url(${StarHalf});
  `}
`;
export default function Rating({
  platform,
  rating,
  image
}) {
  const ratingNumber = (Math.round(Number(rating) * 2) / 2).toFixed(1);

  const stars = [];
  for (let i = 1; i <= ratingNumber; i += 1) {
    stars.push(<RatingStar full key={i} />);
  }
  if (ratingNumber % 1 === 0.5) {
    stars.push(<RatingStar half src={StarHalf} key="half" />);
  }

  return (
    <RatingContainer>
      <PlatformImage src={image} alt={`${platform} icon`} />
      <RatingContent>
        <PlatformName>
          {platform}
        </PlatformName>
        <PlatformRating>
          {stars}
        </PlatformRating>
      </RatingContent>
    </RatingContainer>
  );
}

Rating.propTypes = {
  platform: PropTypes.string,
  rating: PropTypes.number,
  image: PropTypes.string
};
