// TODO: create tests
const calculateSliderPos = (value, max, min) => {
  const thumbRadius = 17;
  const fract = (value - min) / (max - min);
  const percentLeft = fract * 100;
  const fractFromCentre = (fract - 0.55) * 2;
  const adjustment = fractFromCentre * -thumbRadius;

  return [percentLeft, adjustment];
};

export default calculateSliderPos;
