const isProduction = process.env.NODE_ENV === 'production';
const isStaging = process.env.STAGING === 'staging';
const isDevelopment = process.env.NODE_ENV === 'development';
// eslint-disable-next-line no-console
console.log('Environment:', process.env.NODE_ENV);

let rootUrl = '/';

if (isStaging) {
  rootUrl = 'https://staging.inator.com.au/';
} else if (isProduction) {
  rootUrl = 'https://nimble.com.au/';
} else if (isDevelopment) {
  rootUrl = 'http://localhost:8000/';
}
export const routes = {
  global: {
    fees: {
      min_loan_amount: '$300',
      max_small_loan_amount: '$2,000',
      min_medium_loan_amount: '$2,050',
      max_loan_amount: '$5,000',
      macc_apr: '47.6158%',
      macc_comparison_rate: '65.6597%',
      min_personalloan_amount: '$5,000',
      max_personalloan_amount: '$25,000',
      max_personalloan_borrow_amount: '$25,000'
    },
    amp: {
      use_amp: false,
      use_accordion: false,
      use_form: false,
      use_notification: false,
      use_discountmsg: true
    },
    urls: {
      domains: {
        root: rootUrl
      },
      external: {
        replatformlogin: 'https://nimble.com.au/s1/get-started/',
        memberarea: `${rootUrl}account`,
        apply: `${rootUrl}application/get-started`,
        applyhome: `${rootUrl}#loan-selector`,
        getstarted: `${rootUrl}application/get-started`,
        activate: `${rootUrl}activate`,
        creditguide: `${rootUrl}documents/personal_loan_credit_guide.pdf`,
        feestatement: `${rootUrl}documents/fee_statement.pdf`,
        feesstatement: `${rootUrl}content/files/fees-statement.pdf`,
        nimblecardpds:
          '//assets.ctfassets.net/930h20wnqvpb/cpdM9MAE6pGqrn03UjceW/83cff7e1bb40e291aa3b3818cdacfe5e/EML_Visa_ReloadablePrepaidCard_PDS.pdf',
        nimblecardfsg:
          '//assets.ctfassets.net/930h20wnqvpb/TqOdkSLtxU1PtJIglzWFy/42fd3cb58f25344a04d2fb78359e776b/EPSL_FSG_VisaReloadable.pdf',
        disputeresolution: `${rootUrl}content/files/nimble_dispute_resolution_process.pdf`,
        financialhardshipform: `${rootUrl}content/files/financial_hardship_application_form.pdf`,
        facebook: 'https://www.facebook.com/NimbleAustralia',
        twitter: 'https://twitter.com/nimbleaustralia',
        linkedin: 'https://www.linkedin.com/company/nimble-australia-ltd',
        youtube: 'https://www.youtube.com/user/NimbleMoney',
        instagram: 'https://www.instagram.com/nimbleaustralia/',
        contactsubmit: `${rootUrl}mobile/submitcontact/`,
        forgotpassswordsubmit: `${rootUrl}mobile/submitforgotpassword/`,
        loginsubmit: `${rootUrl}mobile/submitlogin/`,
        notification: `${rootUrl}mobile/announcementmessage`,
        discountmsg: `${rootUrl}mobile/saccnldiscountmessage`,
        applicationcount: `${rootUrl}mobile/applicationcount`,
        googleplay:
          'https://play.google.com/store/apps/details?id=au.com.nimble.nimbleit',
        appstore: 'https://itunes.apple.com/au/app/nimble-it/id1266569899?mt=8',
        personalloanapplication: `${rootUrl}personal-loan/`,
        carloanapplication: `${rootUrl}car-loan`
      },
      local: {
        home: rootUrl,
        faq: `${rootUrl}faq/`,
        contact: `${rootUrl}contact/`,
        nimblecard: `${rootUrl}nimble-card/`,
        security: `${rootUrl}security/`,
        reviews: `${rootUrl}reviews/`,
        aboutus: `${rootUrl}about-us/`,
        managementteam: `${rootUrl}about-us/management-team/`,
        mediaroom: `${rootUrl}about-us/media-room/`,
        careers: `${rootUrl}about-us/careers/`,
        financialhardship: `${rootUrl}financial-hardship/`,
        responsiblelending: `${rootUrl}about-us/responsible-lending/`,
        bankfeed: `${rootUrl}about-us/bankfeedterms/`,
        feedback: `${rootUrl}about-us/complaints/`,
        shorttermloans: `${rootUrl}short-term-loans/`,
        onlineloans: `${rootUrl}online-loans/`,
        cashloans: `${rootUrl}cash-loans/`,
        comparenimbleloans: `${rootUrl}loans/compare-nimble-loans/`,
        fastloans: `${rootUrl}fast-loans/`,
        bondloans: `${rootUrl}bond-loans/`,
        cashadvance: `${rootUrl}cash-advance/`,
        warningaboutborrowing: `${rootUrl}warning-about-borrowing/`,
        login: `${rootUrl}login/`,
        forgotpassword: `${rootUrl}login/forgot-password/`,
        privacypolicy: `${rootUrl}policy/privacy/web.html`,
        websitepolicy: `${rootUrl}policy/website/web.html`,
        terms: `${rootUrl}policy/terms/web.html`,
        personalloansterms: `${rootUrl}policy/terms/web.html`,
        loans: `${rootUrl}loans/`,
        loanscashloans: `${rootUrl}loans/cash-loans/`,
        loanssmallloans: `${rootUrl}loans/small-loans/`,
        loansshorttermloans: `${rootUrl}loans/short-term-loans/`,
        loanssamedayloans: `${rootUrl}loans/same-day-loans/`,
        loansquickloans: `${rootUrl}loans/quick-loans/`,
        loanspersonalloans: `${rootUrl}loans/large-loans/`,
        loanspaydayloans: `${rootUrl}loans/payday-loans/`,
        loansonlineloans: `${rootUrl}loans/online-loans/`,
        loansfastloans: `${rootUrl}loans/fast-loans/`,
        loanscomparenimbleloans: `${rootUrl}loans/compare-nimble-loans/`,
        loanscarrepairloans: `${rootUrl}loans/car-repair-loans/`,
        loansweddingloans: `${rootUrl}loans/wedding-loans/`,
        loansholidayloans: `${rootUrl}loans/holiday-loans/`,
        loansstudentloans: `${rootUrl}loans/student-loans/`,
        rss: `${rootUrl}blog/feed/`,
        blog: `${rootUrl}blog/`
      },
      personalLoans: {
        personalloans: `${rootUrl}personal-loans/`,
        personalloans_responsiblelending: `${rootUrl}personal-loans/responsible-lending/`,
        personalloans_bankfeedterms: `${rootUrl}personal-loans/bankfeedterms/`,
        personalloans_feedback: `${rootUrl}personal-loans/complaints/`,
        personalloans_privacy: `${rootUrl}policy/personal-loans/privacy/web.html`,
        personalloans_website: `${rootUrl}policy/personal-loans/website/web.html`,
        personalloans_costs: `${rootUrl}faq/how-much-does-it-cost/personal-loans.html`,
        personalloans_faq: `${rootUrl}faq/personal-loans.html`
      },
      appLinks: {
        app_privacypolicy: `${rootUrl}policy/privacy/`,
        app_websitepolicy: `${rootUrl}policy/website/`,
        app_terms: `${rootUrl}policy/terms/`
      },
      slider: {
        slider_local: `${rootUrl}shared/iframes/slider.html`,
        slider_external: `${rootUrl}application/slider`
      },
      documents: {
        personalloans_tcs: `${rootUrl}documents/personal_loan_terms_and_conditions.pdf`,
        personalloans_feestatement: `${rootUrl}documents/personal_loan_fee_statement.pdf`,
        personalloans_creditguide: `${rootUrl}documents/personal_loan_credit_guide.pdf`
      },
      anyTime: {
        entry: isStaging || isDevelopment ? 'https://staging.at.nimble.com.au/welcome' : 'https://at.nimble.com.au/welcome',
        signin: isStaging || isDevelopment ? 'https://staging.at.nimble.com.au/sign-in' : 'https://at.nimble.com.au/sign-in'
      },
      anyTimeNewAccount: {
        entry: isStaging || isDevelopment ? 'https://staging.at.nimble.com.au/create-account' : 'https://at.nimble.com.au/create-account'
      },
      cashLoanApply: {
        entry: isStaging || isDevelopment ? 'https://staging.inator.com.au/application/get-started' : 'https://nimble.com.au/application/get-started'
      },
      memberArea:{
        entry: isDevelopment ? 'http://localhost:1285' : isStaging  ? 'https://staging.inator.com.au': 'https://nimble.com.au'
      },
      returnerFlow:{
        entry: isDevelopment ? 'http://localhost:1285/get-started' : isStaging  ? 'https://staging.inator.com.au/get-started': 'https://nimble.com.au/get-started'
      }
    },
    keys: {
      external: {
        reCaptcha_sitekey: '6Lf4gAoUAAAAAPE0e38GYNMFXsHT7FCUHf3IW_Z0'
      }
    }
  }
};

export const signin = 'signin';
