/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Rating from './rating.component';
import BannerBunnies from '../../../../static/images/homepage-hero-bunny-pattern.png';

const Background = styled.div`
  position: relative;
  z-index: 1;
  padding: 20px 0;
  overflow: hidden;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0 0 20px;
  }  
`;

const BackgroundShape = styled.svg`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    width: 125%;
    transform: translateX(-12%);
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    width: 150%;
    transform: translateX(-17%);
  }
`;

const BackgroundBunnies = styled.div`
  position: absolute;
  background-repeat: repeat;
  background-size: 202px 285px;
  background-position: 0 15px;
  background-image: url(${BannerBunnies});
  width: 100%;
  height: 100%;
`;

const ReviewBarContainer = styled.div`
  padding: 45px 60px 0;
  max-width: 1403px;
  margin: auto;
  color: #ffffff;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    display: block;
    padding: 110px 60px 0;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 60px 20px 0;
  }  
`;

const TitleContainer = styled.div`
  flex: 0 0 50%;
`;
const StyledTitle = styled.p`
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  margin-top: 0%;
  margin-bottom: 12px;
  text-transform: uppercase;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) { 
    text-align: center;
  }
`;

const RatingsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -15px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    justify-content: center;
    margin: 0 -5px;
  }
`;

export default function ReviewBar({
  reviewData
}) {
  const { ratingsTitle, ratings } = reviewData;

  return (
    <Background>
      <BackgroundShape viewBox="0 0 1440 137" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0C0 0 437.725 22.4007 718.5 22.3658C1000.45 22.3307 1440 0 1440 0V184.986C1440 184.986 1000.41 185.018 718.5 184.986C437.758 184.954 0 184.986 0 184.986V0Z" fill="#DB1A1A" />
      </BackgroundShape>
      <BackgroundBunnies />
      <ReviewBarContainer>
        <TitleContainer>
          <StyledTitle>{ratingsTitle}</StyledTitle>
        </TitleContainer>

        <RatingsContainer>
          {
            ratings.map(({
              name,
              ratingNumber,
              logo
            }) => {
              return (
                <Rating
                  key={name}
                  platform={name}
                  rating={ratingNumber}
                  image={logo.file.url}
                />
              );
            })
          }
        </RatingsContainer>
      </ReviewBarContainer>
    </Background>
  );
}

ReviewBar.propTypes = {
  reviewData: PropTypes.shape({
    ratingsTitle: PropTypes.string,
    ratings: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.shape({
        file: PropTypes.objectOf(PropTypes.string)
      }),
      ratingNumber: PropTypes.number
    }))
  })
};
