import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import withinRange from '../../utilities/numberHelpers';
import RangeInput from './rangeInput';

const RangeTextMinimum = styled.span`
  color: #4f4f4f;
  padding: 4px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  order: 1;
  @media ${props => props.theme.mediaBreakpoints.tablet} {
    order: 2;
  }
`;

const RangeTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RangeTextMaximum = styled.span`
  color: #4f4f4f;
  padding: 4px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  order: 3;
  @media ${props => props.theme.mediaBreakpoints.tablet} {
    order: 3;
  }
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;

  ${props => props.hasButtons && css`
    padding: 0px 45px;
  `};

  ${props => props.hasToolitip && css`
    margin: 45px auto 20px;
  `};
`;

const UpdateButton = styled.button`
  position: absolute;
  top: 0px;
  font-family: ${props => props.theme.font.nimbleFont};
  color: ${props => props.theme.colours.slate};
  font-weight: 400;
  background-color: #ffffff;
  padding: 3px 8px 0px;
  font-size: 25px;
  line-height: 26px;
  border-radius: 5px;
  border: 0.5px solid ${props => props.theme.colours.slate};
  transition: 0.35s ease;
  cursor: pointer;

  &:focus{
    background-color: #ffffff;
  }

  @media(hover: hover){
    &:hover{
      color: #ffffff;
      background-color: ${props => props.theme.colours.darkGreen};
    }
  }

  ${props => props.addAmount && css`
    right: 0px;
  `};
  ${props => props.removeAmount && css`
    left: 0px;
  `};
`;

const RangeSlider = ({
  onChangeCallback,
  inputName,
  ariaLabel,
  value,
  sliderStep,
  hasButtons,
  buttonStep,
  min,
  max,
  formatter,
  hasLabels,
  labelsFormatter,
  hasToolitip
}) => {
  const [sliderAmount, setSliderAmount] = useState(value);

  useEffect(() => {
    setSliderAmount(value);
  }, [value]);

  const handleChange = e => {
    const change = withinRange(
      Number.parseInt(Number(e.target.value), 10),
      min,
      max
    );
    setSliderAmount(change);
    onChangeCallback(change);
  };
  const updateAmount = (updateType) => {
    if (updateType === 'add' && sliderAmount !== max) {
      if (sliderAmount === min && !Number.isInteger(sliderAmount / buttonStep)) {
        setSliderAmount(buttonStep);
        onChangeCallback(buttonStep);
      } else if (sliderAmount + buttonStep <= max) {
        setSliderAmount(sliderAmount + buttonStep);
        onChangeCallback(sliderAmount + buttonStep);
      } else {
        setSliderAmount(max);
        onChangeCallback(max);
      }
    } else if (updateType === 'remove' && sliderAmount !== min) {
      if (sliderAmount - buttonStep >= min) {
        setSliderAmount(sliderAmount - buttonStep);
        onChangeCallback(sliderAmount - buttonStep);
      } else {
        setSliderAmount(min);
        onChangeCallback(min);
      }
    }
  };

  return (
    <SliderContainer hasButtons={hasButtons} hasToolitip={hasToolitip}>
      {hasButtons && <UpdateButton removeAmount aria-label="Remove Amount" type="button" onClick={() => updateAmount('remove')}>-</UpdateButton>}
      <RangeInput
        name={inputName}
        ariaLabel={ariaLabel}
        type="range"
        min={min}
        max={max}
        value={sliderAmount}
        onChange={handleChange}
        step={sliderStep > max - sliderAmount ? max - sliderAmount : sliderStep}
        formatter={formatter}
        tooltip={hasToolitip}
      />
      {hasButtons && <UpdateButton addAmount aria-label="Add Amount" type="button" onClick={() => updateAmount('add')}>+</UpdateButton>}
      {hasLabels && (
        <RangeTextContainer>
          <RangeTextMinimum>
            {labelsFormatter(min)}
          </RangeTextMinimum>
          <RangeTextMaximum>
            {labelsFormatter(max)}
          </RangeTextMaximum>
        </RangeTextContainer>
      )}
    </SliderContainer>
  );
};

RangeSlider.propTypes = {
  onChangeCallback: PropTypes.func,
  inputName: PropTypes.string,
  ariaLabel: PropTypes.string,
  value: PropTypes.number.isRequired,
  sliderStep: PropTypes.number.isRequired,
  hasButtons: PropTypes.bool,
  buttonStep: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  formatter: PropTypes.func,
  hasLabels: PropTypes.bool,
  labelsFormatter: PropTypes.func,
  hasToolitip: PropTypes.bool
};
export default RangeSlider;
